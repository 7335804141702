<template>
  <div>
    <LoadingSpinner :is-loaded="bookletBlockLoaded">
      <ContentEditor
        title="Úprava rozprávkového bloku"
        :alert="
          booklet.visible ? 'Pozor, upravuješ verejný rozprávkový blok.' : null
        "
        :saving="saving"
        :initial="rawMarkdown"
        :model-id="bookletId"
        :upload-function="apiPostBookletAttachment"
        :delete-function="apiDelBookletAttachment"
        :reload-function="apiBookletAttachments"
        :info-function="apiHeadBookletAttachment"
        @save-text="save"
      />
    </LoadingSpinner>
  </div>
</template>

<script>
import { apiBooklets, LoadingSpinner } from "frontend-common";
import ContentEditor from "@/components/ContentEditor";

export default {
  name: "BookletTextBlockChange",
  components: {
    ContentEditor,
    LoadingSpinner,
  },
  mixins: [apiBooklets],
  props: {
    bookletId: {
      type: Number,
      required: true,
    },
    blockId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      bookletBlockLoaded: false,
      booklet: {},
      block: {},
      rawMarkdown: "",
      saving: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.apiBooklet(this.bookletId)
      .then((response) => (this.booklet = response))
      .finally(() => {
        this.block = this.booklet.blocks.find(
          (block) => block.id === this.blockId,
        );
        this.rawMarkdown = this.block.text;
        this.bookletBlockLoaded = true;
      });
  },
  methods: {
    save(newText) {
      this.saving = true;
      this.apiUpdateBooklet(this.bookletId, {
        blocks: [
          {
            block_type: "text",
            position: this.block.position,
            text: newText,
          },
        ],
      }).then(() => {
        this.$root.successToast("Text úspešne uložený!");
        this.saving = false;
      });
    },
  },
};
</script>

<style scoped></style>
